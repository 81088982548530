import React, {useState,useEffect} from "react";
import styles from "./ProjectsListGaming.module.css"

import ProjectGaming from "./Project/ProjectGaming";

const ProjectsListGaming = (props) => {
    const [projectsData] = useState(
        [
            {
                title: 'VETERANS ONLINE',
                duration:'4 Years',
                description:
                    '-Implemented and responsible of all the UI/UX of the game.\n' +
                    '-Implementation of the Localization system in the game.\n' +
                    '-Implementation of a tool to manage the Localisation system and its different languages.\n' +
                    '-Implementation of a Ping system Client and Server side.\n' +
                    '-Implementation of achievements, daily missions and daily rewards (Client side).\n' +
                    '-Implementation of top players board.\n' +
                    '-Implementation of managing friends list and managing grouping with friends.\n' +
                    '-Implementation of the radar system (Client side).\n' +
                    '-Implementation of the minimap system (Client side).\n' +
                    '-Implementation of Discord Bots (Node.js).\n' +
                    '-Much more, I can\'t list everything...',
                thumbnail: '/img/projects//gaming/VeteransOnline.jpg',
                link: "https://youtu.be/4q8xvNME4rk"
            },
            {
                title: 'RUG OF DUTY (DEMO)',
                duration:'4 Months',
                description:
                    '(as Game Lead)\n' +
                    '-Networking done with Photon Fusion.\n' +
                    '-Server/client logic used.\n' +
                    '-Watch video for gameplay footage.',
                thumbnail: '/img/projects//gaming/Rug Of Duty.png',
                link: "https://drive.google.com/file/d/1SB4kwp6zyLCcstFYReKKSmFyGmpTm49P/view?usp=sharing"
            },
            {
                title: 'FIGHTERS RUN',
                duration:'1 MONTH',
                description:
                    '-Hyper Casual Game (With Rollic)',
                thumbnail: '/img/projects//gaming/Fighters Run.png',
                link: "https://drive.google.com/file/d/1Ku0hYjIeukfZyTRZGQ2uobScNxN00L1S/view"
            },
            {
                title: 'NINJA SCARF',
                duration:'2 Weeks',
                description:
                    '-Hyper Casual Game (With Rollic)',
                thumbnail: '/img/projects//gaming/Ninja Scarf.png',
                link: "https://drive.google.com/file/d/1fkcW42atAfxMMugbAB4FRRDMiq79bWv7/view?usp=sharing"
            },
            {
                title: 'ICE CREAM GANG 3D',
                duration:'2 Weeks',
                description:
                    '-Hyper Casual Game (With Rollic)',
                thumbnail: '/img/projects//gaming/Ice Cream Gang.png',
                link: "https://drive.google.com/file/d/1X47857WgCUkB6Y7NJGM7ED0dxVKeiHo2/view?usp=sharing"
            },
            {
                title: 'SPLASH THE COKE',
                duration:'2 Weeks',
                description:
                    '-Hyper Casual Game (With Rollic)',
                thumbnail: '/img/projects//gaming/Splash The Coke.png',
                link: "https://drive.google.com/file/d/12w1RNZpmCxufNrMwLTFtc9BqXZ9lCDtS/view?usp=sharing"
            },
            {
                title: 'ANGRY PANDA',
                duration:'2 Weeks',
                description:
                    '-Hyper Casual Game (With Rollic)',
                thumbnail: '/img/projects//gaming/Angry Panda.png',
                link: "https://drive.google.com/file/d/1iA5SCfs7q8QYErai2frEkXf049VxK85V/view?usp=sharing"
            },
            {
                title: 'KISSING BOOTH',
                duration:'2 Weeks',
                description:
                    '-Hyper Casual Game (With TapNation)',
                thumbnail: '/img/projects//gaming/Kissing Booth.png',
                link: "https://drive.google.com/file/d/1cHNsSedoeEov5hQeULYMu7e74A7LVetD/view?usp=sharing"
            },
            {
                title: 'SUPER MULTITASK WOMEN',
                duration:'2 Weeks',
                description:
                    '-Hyper Casual Game (With Rollic)',
                thumbnail: '/img/projects//gaming/Super Multitask Women.png',
                link: "https://drive.google.com/file/d/1PADHrKrptitf13ci82otwAaWWgDdZfGz/view?usp=sharing"
            },
            {
                title: 'RICH AND POOR',
                duration:'2 Weeks',
                description:
                    '-Hyper Casual Game (With Rollic)',
                thumbnail: '/img/projects//gaming/Rich And Poor.png',
                link: "https://drive.google.com/file/d/1v0i03EmiGzPijYtlhxTDfHC_dW5FGPTx/view?usp=sharing"
            },
            {
                title: 'YOUR LUCKY RUN',
                duration:'4 DAYS',
                description:
                    '-Hyper Casual Game (With Rollic)',
                thumbnail: '/img/projects//gaming/Your Lucky Run.png',
                link: "https://drive.google.com/file/d/1UmH8pbaXBpesIIBXRf0hXNL6EDH3pJQe/view?usp=sharing"
            }]
    )

    const [index, setIndex] = useState(0)
    const [projectDataArray, setProjectDataArray] = useState([]);
    useEffect(() => {
        const timeout = setTimeout(() => {
            setProjectDataArray(projectDataArray.concat(projectsData[index]))
            if (index < projectsData.length - 1)
                setIndex(index + 1)

        }, 300);

        return () => clearTimeout(timeout)
// eslint-disable-next-line
    }, [index])
    let styleClasses = [styles.ProjectsList];
    if (props.styles)
        styleClasses.push(props.styles)

    let projects = projectDataArray.map(project => (
        <ProjectGaming key={project.title} duration={project.duration} thumbnail={project.thumbnail} title={project.title}
                    description={project.description}
                    link={project.link}/>
    ))

    return (
        <div className={styleClasses.join(' ')}>
            {projects}

        </div>
    )
}

export default ProjectsListGaming;